<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <el-form :inline="true" size="mini">
        <AreaField v-model="searchParams.area_code" @change="getStationList" />
        <el-form-item>
          <el-select
            v-model="searchParams.site_id"
            filterable
            clearable
            placeholder="加油站（可输入）"
          >
            <el-option
              v-for="item in gasData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchParams.depart_id"
            filterable
            clearable
            placeholder="所属机关"
          >
            <el-option
              v-for="item in departData"
              :key="item.depart_id"
              :value="item.depart_id"
              :label="item.depart_name"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchParams.register_type"
            filterable
            placeholder="登记注册类型"
            :clearable="true"
          >
            <el-option
              v-for="item in registerTypes"
              :key="item.id"
              :value="item.name"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="date"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            placeholder="时间范围"
            clearable
            @change="getTime"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
          >
            查询
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
          >
            导出
          </el-button>
        </el-form-item>
      </el-form>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
    </default-table>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import { getSiteSum, ExportSiteSum } from '@/api/stat';

import mixins from '@/mixins';
import { getRegisterFactory } from '@/api/global';
import { getDepartDetail } from '@/api/gas_info_mgr';

export default {
  mixins: [mixins],
  components: {
    PageHeader,
    DefaultTable
  },
  data() {
    return {
      tableLoading: true,
      searchParams: {},
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 64,
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '加油站名称',
          key: 'site_name',
          align: 'left',
          minWidth: 92
        },
        {
          title: '所属机关',
          key: 'depart_name'
        },
        {
          title: '销售油量(L)',
          key: 'quantity',
          minWidth: 114
        },
        {
          title: '销售金额(元)',
          key: 'total',
          minWidth: 114
        }
      ],
      data: [],
      date: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      registerTypes: [],
      departData: []
    };
  },
  computed: {
    title() {
      const currentName = this.$router.history.current.name;
      return this.$locales[currentName];
    }
  },
  created() {
    // if (this.$route.query.status) this.searchParams.status = '0';
    this.pathMatch = this.$router.history.current.matched;

    this.getRegisterFactory();

    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleQuery({ current, pageSize }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      getSiteSum(params).then(res => {
        // console.log(res);
        this.data = res.data;
        if (res.code === 0) {
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
        } else {
          this.pagination = {
            ...this.pagination,
            total: 0
          };
        }
        this.tableLoading = false;
      });
    },
    handleCurrentChange(current) {
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };
      ExportSiteSum(params)
        .then(res => {
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    async getRegisterFactory() {
      const result = await getRegisterFactory({ type: 1 });
      if (result && result.code === 0) {
        this.registerTypes = result.data;
      }

      getDepartDetail().then(res => {
        let { code, data } = res;
        if (data && code === 0) {
          this.departData = data;
        }
      });
    },
    getTime(e) {
      if (e) {
        this.searchParams.begin = e[0];
        this.searchParams.end = e[1];
      } else {
        this.searchParams.begin = '';
        this.searchParams.end = '';
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
